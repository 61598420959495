<template>
  <Portlet
    :title="$t('softwareComponent.softwareComponentType')"
    icon="hotel"
    class="softwareComponentTypes"
  >
    <template slot="buttons">
      <button 
        class="btn btn-sm btn-primary float-right mt-2 mb-2" 
        @click="showSidebarAdd"
      >
        <font-awesome-icon
          class="mr-1"
          icon="plus"
        />
        <span>
          {{ $t('add') }}
        </span>
      </button>
      <div class="clearfix" />
    </template>
    <SoftwareComponentTypeList
      ref="softwareComponentTypeList"
      @reloadAuditLogs="reloadAuditLogs"
    /> 
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :is-software-component-type="true"
      @reload="reloadSoftwareComponentTypeList"
    />
  </Portlet>
</template>

<script>
export default {
    name: 'SoftwareComponentTypes',
    components: {
        SoftwareComponentTypeList: () => import('@/components/SoftwareComponent/SoftwareComponentTypeList.vue'),
        DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
    },
    metaInfo () {
        return {
            title: this.$t('softwareComponent.softwareComponentType')
        }
    },
    methods: {
      showSidebarAdd () {
        this.$refs.softwareComponentTypeList.showSidebarAdd();
      },
      reloadAuditLogs () {
        this.$refs.databaseAuditLogList.reloadAuditLogs();
      },
      reloadSoftwareComponentTypeList () {
        this.$refs.softwareComponentTypeList.reloadSoftwareComponentTypes();
      }
    }
}
</script> 